import React from 'react';

import Header from './components/Header';
import Main from './components/Main';
import Advantages from './components/Advantages';
import Order from './components/Order';
import Info from './components/Info';
import Product from './components/Product';
import Clients from './components/Clients';
import Calculator from './components/Calculator';
import Equipment from './components/Equipment';
import Review from './components/Review';
// import FAQ from './components/FAQ';
import Footer from './components/Footer';
import PopupOpener from './components/Modals/PopupOpener';
import data from './data.json';
import StoreProvider from './store';
import VideoAbout from './components/VideoAbout';

function App(): JSX.Element {
  return (
    <StoreProvider>
      <PopupOpener />
      <Header />
      <Main />
      <VideoAbout />
      <Advantages />
      <Order number={data.number} />
      <Info />
      <Product />
      <Clients />
      <Calculator />
      <Equipment equipment={data.equipment} />
      {/* <div style={{ boxShadow: 'inset 0px 0px 19px rgba(0, 0, 0, 0.25)' }}> */}
      <Review />
      {/* <FAQ /> */}
      {/* </div> */}
      <Footer />
    </StoreProvider>
  );
}

export default App;
