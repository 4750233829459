import React, { useState, useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

// import LoginButton from '../Buttons/LoginButton';
import { StoreContext } from '../../store';

import style from './Header.module.css';
import HeaderItem from './HeaderItem';

function Header(): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect());
  const [smallHeader, setSmallHeader] = useState(bodyOffset.top > 20);

  const listener = (): void => {
    setBodyOffset(document.body.getBoundingClientRect());
    setSmallHeader(-bodyOffset.top > 20);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return (): void => {
      window.removeEventListener('scroll', listener);
    };
  });

  function scrollToTop(): void {
    animateScroll.scrollToTop();
  }

  function hideMenu(): void {
    setOpen(false);
  }

  // const { contactUs } = React.useContext(StoreContext);
  React.useContext(StoreContext);

  function handleToggleClick(): void {
    setOpen(!isOpen);
  }

  return (
    <header className={cn(style.header, smallHeader && style.small)}>
      <nav className={style.nav}>
        <button className={style.logo} onClick={scrollToTop} aria-label="logo" />
        <ul>
          <OutsideClickHandler onOutsideClick={hideMenu}>
            <div className={cn(style.itemsContainer, isOpen && style.mobileMenu)}>
              <HeaderItem onClick={hideMenu} to={'advantages'} className={cn(style.item, isOpen && style.itemAppear)}>
                Преимущества
              </HeaderItem>
              <HeaderItem onClick={hideMenu} to={'product'} className={cn(style.item, isOpen && style.itemAppear)}>
                Продукт
              </HeaderItem>
              <HeaderItem onClick={hideMenu} to={'clients'} className={cn(style.item, isOpen && style.itemAppear)}>
                Клиенты
              </HeaderItem>
              <HeaderItem onClick={hideMenu} to={'calculator'} className={cn(style.item, isOpen && style.itemAppear)}>
                Калькулятор
              </HeaderItem>
              <HeaderItem onClick={hideMenu} to={'equipments'} className={cn(style.item, isOpen && style.itemAppear)}>
                Оборудование
              </HeaderItem>
              <HeaderItem onClick={hideMenu} to={'review'} className={cn(style.item, isOpen && style.itemAppear)}>
                Отзывы
              </HeaderItem>
            </div>
          </OutsideClickHandler>
          {/* <li className={style.login}>
            <LoginButton openSignUp={contactUs.open} />
          </li> */}
          <div className={style.line} />
          <div className={style.contacts}>
            <li className={style.mail}>
              <a href="mailto:office@innerica.ru">office@innerica.ru</a>
            </li>
            <li className={style.phone}>
              <a href="tel:+7(985)305-05-41">+7 (985) 305-05-41</a>
            </li>
          </div>
        </ul>
        <button className={style.menu} onClick={handleToggleClick} aria-label="toggle" type="button">
          Меню
        </button>
      </nav>
    </header>
  );
}

export default Header;
